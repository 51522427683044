import React, { useEffect, useState, useRef } from "react";
import Seo from "../../components/common/seo.js";
import Layout from "../../components/common/layout.js";
import { Link } from "gatsby";
import { fullDate } from '../../utils/date_format.js';

const ResourceCenter = () => {
  const [resources, setResources] = useState([]);
  const [search, setSearch] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [allTags, setAllTags] = useState([]);
  const resourcesPerPage = 12;

  useEffect(() => {
    fetch('/.netlify/functions/resources')
      .then(res => res.json())
      .then(data => {
        const sortedResources = data.resources.sort((a, b) => {
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);
          return dateB - dateA; // Sort in descending order (most recent first)
        });
        setResources(sortedResources);
        extractTags(sortedResources);
      })
      .catch(error => {
        console.error('Error fetching resources:', error);
      });
  }, []);
  
  const extractTags = (resources) => {
    const tagsSet = new Set();
    resources.forEach(resource => {
      resource.newTags['en-CA']?.forEach(tag => tagsSet.add(tag));
    });
    setAllTags([...tagsSet]);
  };

  const filteredResources = resources.filter(resource => {
    const searchWords = search.toLowerCase().split(" ");

    const dateMatchesSearch = (date) => {
      const formattedDate = fullDate(date);
      return searchWords.every(word => formattedDate.toLowerCase().includes(word));
    };

    const titleMatch = !search || searchWords.every(word => resource.title['en-CA'].toLowerCase().includes(word));
    const excerptMatch = !search || searchWords.every(word => resource.excerpt['en-CA'].toLowerCase().includes(word));
    const contentMatch = !search || searchWords.every(word => 
      typeof resource.content['en-CA'] === 'string' && resource.content['en-CA'].toLowerCase().includes(word)
    );    
    const tagMatch = !search || searchWords.every(word => resource.newTags['en-CA'].some(tag => tag.toLowerCase().includes(word)));
    const dateMatch = !search || dateMatchesSearch(resource.date['en-CA']);

    return (
      (titleMatch || excerptMatch || contentMatch || tagMatch || dateMatch) &&
      (selectedTags.length === 0 || resource.newTags['en-CA'].some(tag => selectedTags.includes(tag)))
    );
  });

  const handleClick = (e, tag) => {
    e.preventDefault();
    setSelectedTags((prevTags) =>
      prevTags.includes(tag) ? prevTags.filter((t) => t !== tag) : [...prevTags, tag]
    );
    setCurrentPage(1);
  };

  const handleViewAllClick = (e) => {
    e.preventDefault();
    setSelectedTags([]);
    setCurrentPage(1);
  };

  const indexOfLastResource = currentPage * resourcesPerPage;
  const indexOfFirstResource = indexOfLastResource - resourcesPerPage;
  const currentResources = filteredResources.slice(indexOfFirstResource, indexOfLastResource);

  const pageNumbers = Array.from({ length: Math.ceil(filteredResources.length / resourcesPerPage) }, (_, i) => i + 1);

  const hasRunOnInitialLoad = useRef(false);

  (function() {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      if (window.location.pathname.includes('/fr/')) {
        document.body.classList.add('fr');
      }
    }
  })();

  return (
    <div className="resourcesPage">
      <div className="resourcesHero">
        <div className="container">
          <h1>
            <span>COMMB</span> Resource Centre
          </h1>
          <p>
            This resource hub is aimed at supporting the OOH advertising
            industry with the relevant information and proof points on the value
            of OOH.
          </p>
          <div className="resourceSearchWrapper">
            <input
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <svg
              width="23"
              height="23"
              viewBox="0 0 23 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 16.5L21 21.5"
                stroke="#505050"
                strokeWidth="2"
                strokeLinecap="square"
                strokeLinejoin="round"
              />
              <path
                d="M1 10.0714C1 14.8053 4.83756 18.6429 9.57143 18.6429C11.9424 18.6429 14.0887 17.6801 15.6404 16.1243C17.1869 14.5739 18.1429 12.4343 18.1429 10.0714C18.1429 5.33756 14.3053 1.5 9.57143 1.5C4.83756 1.5 1 5.33756 1 10.0714Z"
                stroke="#505050"
                strokeWidth="2"
                strokeLinecap="square"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="resourceHeroTagBtnWrapper">
            {allTags.map((tag) => (
              <a
                key={tag}
                href="#"
                onClick={(e) => handleClick(e, tag)}
                className={selectedTags.includes(tag) ? 'active' : ''}
              >
                {tag}
              </a>
            ))}
            <a
              href="#"
              className={`viewAllResourcesBtn ${
                selectedTags.length === 0 ? 'active' : ''
              }`}
              onClick={handleViewAllClick}
            >
              View All
            </a>
          </div>
        </div>
      </div>

      {/*
        Here’s the key line:
        If `search` has something in it, we add the "searching" class 
        to the wrapper in addition to "resourceCardWrapper"
      */}
      <div className={`resourceCardWrapper ${search.trim() ? 'searching' : ''}`}>
        <div className="container">
          {currentResources.length > 0 ? (
            currentResources.map((resource) => (
              <div className="resourceGridCard" key={resource.objectID}>
                <Link to={`/resource-centre/${resource.slug['en-CA']}`}>
                  <img
                    src={resource.imageEn}
                    alt={resource.title['en-CA']}
                  />
                </Link>
                <div className="resourceCardDetails">
                  <div className="pillsCategoryWrapper">
                    {resource.newTags['en-CA'].map((tag, index) => (
                      <span key={index} className="pillsCategory">
                        {tag}
                      </span>
                    ))}
                  </div>
                  <h2>
                    <Link to={`/resource-centre/${resource.slug['en-CA']}`}>
                      {resource.title['en-CA']}
                    </Link>
                  </h2>
                  <p>{resource.excerpt['en-CA']}</p>
                  <hr />
                  <div className="resourceCardButtonWrapper">
                    <Link
                      to={`/resource-centre/${resource.slug['en-CA']}`}
                      className="resourceMoreDetailsBtn"
                    >
                      More Details
                    </Link>
                    <a
                      href={resource.fileUrl['en-CA']}
                      className="resourceDownloadBtn"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Download
                      <svg
                        width="12"
                        height="14"
                        viewBox="0 0 12 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.8667 9.74609V12.8658H1.13333V9.74609H0V14.0002H12V9.74609H10.8667Z"
                          fill="#BEBEBE"
                        />
                        <path
                          d="M2.94424 7.03327L6.04175 10.6946L9.07214 7.03328L6.62098 7.03328L6.62098 -1.06623e-07L5.3954 -1.59935e-07L5.3954 7.03327L2.94424 7.03327Z"
                          fill="#BEBEBE"
                        />
                        <path
                          d="M5.91166 0.514061L5.91166 7.54734L4.05316 7.54734L6.04176 9.89156L7.97977 7.54734L6.10474 7.54734L6.10474 0.514061L5.91166 0.514061Z"
                          fill="#BEBEBE"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No resource found</p>
          )}
        </div>
        
        <div className="resourcePagination">
          <span
            className={`paginationLeftArrow ${
              currentPage === 1 ? 'disabled' : ''
            }`}
            onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)}
          >
            &#9664;
          </span>
          {pageNumbers.map((number) => (
            <span
              key={number}
              className={`paginationBtn ${
                number === currentPage ? 'active' : ''
              }`}
              onClick={() => setCurrentPage(number)}
            >
              {number}
            </span>
          ))}
          <span
            className={`paginationRightArrow ${
              currentPage === pageNumbers.length ||
              currentResources.length < resourcesPerPage
                ? 'disabled'
                : ''
            }`}
            onClick={() => currentPage < pageNumbers.length && setCurrentPage(currentPage + 1)}
          >
            &#9654;
          </span>
        </div>
      </div>
    </div>
  );
};

export default ResourceCenter;
